<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">充电桩管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/base/chargingMarketing">充值管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb> 
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
      <el-form-item label="用户电话" prop="phone">
        <el-input type="text" size="mini" v-model="queryModel.phone"></el-input>
      </el-form-item>
     <el-form-item label="时间范围" prop="rangeTime">
        <el-date-picker
        size="mini"
        v-model="queryModel.rangeTime"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd HH:mm"
        format="yyyy-MM-dd HH:mm">
        </el-date-picker>
      </el-form-item>
     <el-form-item label="充值类型" prop="type">
        <el-select v-model="queryModel.type" clearable placeholder="请选择" size="mini">
            <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订单编号" prop="outOrderNo">
        <el-input type="text" size="mini" v-model="queryModel.outOrderNo"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >查询</el-button>&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
        >重置</el-button>&nbsp;
      </el-form-item>
    </el-form>
       <el-divider></el-divider>
        <el-row class="button-group">
            <el-button
                type="primary"
                size="small"
                plain
                icon="el-icon-download"
                @click="exportXls"
            >导出数据</el-button>
        </el-row>
    <el-table
      :data="tableData"
      style="min-height:400px;"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        prop="outOrderNo"
        label="内部订单编号"
      ></el-table-column>
      <el-table-column
        prop="transactionId"
        label="外部订单编号"
      ></el-table-column>
      <el-table-column
        prop="phone"
        label="用户电话"
      ></el-table-column>
      <el-table-column
        prop="totalFee"
        label="充值金额"
      ></el-table-column>
      <el-table-column
        prop="totalFee"
        label="充值金额"
      ></el-table-column>
      <el-table-column
        prop="body"
        label="充值类型"
      ></el-table-column>
            <el-table-column
        prop="payTime"
        label="支付时间"
      ></el-table-column>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="{row}">
          <el-link type="primary" :underline="false" @click="handleEdit(row)">查看详情</el-link>

          <!--增加权限判断-->  
          <el-link v-if="row.payName=='wechat'" v-show="refundVisible" 
          type="danger" :underline="false" @click="handleRefund(row)">-退款</el-link>

        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <chargingOrder-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></chargingOrder-detail>
  </div>
</template>
<script>
import chargingOrderDetail from "./chargingOrder-detail";
import chargingOrderApi from "@/api/base/chargingOrder";
import permissionApi from "@/api/sys/permission";
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "baseChargingOrderList",
  data() {
    var self = this;

    return {
      queryModel: {
        phone: "",
        outOrderNo:"",
        rangeTime:"",
        type:"",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      showModal2: false,
      modalTitle2: "",
      showModal3: false,
      modalTitle3: "",
      businessKey: "",
      selectedRow: {},
      typeList:[{
          label:"充值",
          value:"1",
      },
      {
          label:"赠送",
          value:"2"
      }],
      refundVisible: false
    };
  },
  methods: {
    handleBindClass(row) {
      this.selectedRow = row;
      this.showBindModal = true;
    },
    handleCloseLive() {
      if(this.player!=null){
        this.player.pause();
      }
    },
    changePage(pageIndex,exportFlag) {
        var self = this;

        self.loading = true;

        self.pageIndex = pageIndex;
        var formData = new FormData();

        formData.append("pageIndex", self.pageIndex);

        formData.append("rangeTime", self.queryModel.rangeTime);
        formData.append("phone", self.queryModel.phone);
        formData.append("outOrderNo", self.queryModel.outOrderNo);
        formData.append("type", self.queryModel.type);

      
        if(exportFlag!=null){
            formData.append("exportFlag", exportFlag);
            formData.append("pageSize", 10000);
        }
        else{
            formData.append("pageSize", self.pageSize);
        }

      chargingOrderApi
        .pageList(formData)
        .then(function (response) {
            self.loading = false;
            var jsonData = response.data;
            if(jsonData.result) {
                if(exportFlag){

                    //导出
                    self.$message({
                        showClose: true,
                        type: "success",
                        message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
                        dangerouslyUseHTMLString: true,
                        duration: 30000
                    });
                }
                else{
                    //分页查看
                    var page = jsonData.data;

                    self.tableData = page.data;
                    self.totalPages = page.totalPages;
                    self.totalElements = page.recordsTotal;
                }
            }
            else{
                self.$message.warning(jsonData.message);
            }
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    exportXls() {
        this.changePage(1,true);
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.sortBy;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          chargingOrderApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },

    handleRefund(record) {
      var self = this;

      var formData = new FormData();

      formData.append("outOrderNo", record.outOrderNo);

      self
        .$confirm("是否确认退款?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          chargingOrderApi.refund(formData).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "退款成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        chargingOrderApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
  },
  created() {

  },
  mounted: function () {
    this.changePage(1);

    permissionApi
      .hasPermission("post", "/wxPay/refund")
      .then((response) => {
        this.refundVisible = response.data.result;
      });
  },
  components: {
    "chargingOrder-detail": chargingOrderDetail,
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}

.capture-image{
  width:160px;
  height: 90px;
  object-fit: container;
}
</style>