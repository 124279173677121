
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="true"
    append-to-body
    style="text-align:left;"
    width="1000px"
    :close-on-click-modal="false"
    @close="closeDialog"
  >
    <div class="user-panel" v-loading="loading">
        <el-row :gutter="20" >
            <el-col :span="8">
                <div class="grid-content bg-purple">
                    <span class="fontText">订单编号：</span> 
                    <span class="data">{{formModel.outOrderNo}}</span>
                </div>
            </el-col>
            <el-col :span="8">
                <span class="fontText">充值金额：</span> 
                <span class="data">{{formModel.totalFee}}</span>
            </el-col>
            <el-col :span="8">
                <span class="fontText">充值类型：</span> 
                <span class="data">{{formModel.body}}</span>
            </el-col>
        </el-row>
        <el-row :gutter="20" >
            <el-col :span="8">
                <div class="grid-content bg-purple">
                    <span class="fontText">支付时间：</span> 
                    <span class="data">{{formModel.payTime}}</span>
                </div>
            </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-container>
              <el-aside width="150px">
                <el-image
                  style="width: 150px; height: 150px"
                  :src="formModel.headImg"
                  :fit="fit">
                </el-image>
              </el-aside>
              <el-main>
                  <el-row >
                    <el-col :span="14">
                        <div class="grid-content bg-purple">
                            <span class="fontText">用户昵称：</span> 
                            <span class="data">{{formModel.openId}}</span>
                        </div>
                    </el-col>
                    <el-col :span="10">
                        <div class="grid-content bg-purple">
                            <span class="fontText">联系电话：</span> 
                            <span class="data">{{formModel.phone}}</span>
                        </div>
                    </el-col>
                </el-row>
                  <el-row>
                    <el-col :span="14">
                        <div class="grid-content bg-purple">
                            <span class="fontText">注册时间：</span> 
                            <span class="data">{{formModel.regUserCreateTime}}</span>
                        </div>
                    </el-col>
                </el-row>
              </el-main>
            </el-container>
          </el-col>
        </el-row>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import chargingOrderApi from "@/api/base/chargingOrder";

export default {
  props: ["businessKey", "title"],
  computed: {
      ruleValidate (){
        var rules = null;
        rules = {
            deviceNo: [
                { required: true, message: "桩号不能为空", trigger: "blur" }
            ],
            name: [
                { required: true, message: "桩名不能为空", trigger: "blur" }
            ],
            templateId:[
              {required: true, message: "收费模式不能为空", trigger: "blur" }
            ]
        };
        return rules;
      }
  },
  data() {
    return {
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {
        templateId:"",
        priceList:[],
      },
      templateList: [],
      defaultPriceList:[],
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            self.formModel.priceList = self.defaultPriceList;

            if (id == null || id.length == 0) {
              return chargingOrderApi.add(self.formModel);
            } else {
              return chargingOrderApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              //self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  created() {
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return chargingOrderApi.create();
      } else {
        return chargingOrderApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data; 
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-panel {
  margin: 10px auto;
  margin-left: 20px;
}
.data{
    margin-left: 50px;
    font-weight:bold
}
.el-row {
margin-bottom: 20px;
}
.el-col {
border-radius: 4px;
}
.grid-content {
border-radius: 4px;
min-height: 36px;
}
.title1{
    margin-bottom: 40px;
}
.title2{
    margin-top: 40px;
    margin-bottom: 40px;
}

</style>